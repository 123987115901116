<div class="sidebar">
    <div *ngIf="viewModel">
        <div *ngIf="viewModel.sidebarContentType === 'search'">
            <app-search-and-multiselect [viewModel]="viewModel"></app-search-and-multiselect>
        </div>
        <div *ngIf="viewModel.sidebarContentType === 'layerUpgrade' && reloadToggle">
            <div *ngIf="viewModel.versionChangelog && viewModel.compareTo">
                <layer-upgrade [viewModel]="viewModel"></layer-upgrade>
            </div>
        </div>
    </div>
</div>
