<div class="help-dialog">
    <div class="content markdown">
        <div class="col toc">
            <h1>Table of Contents</h1>
            <ul class="table-of-contents" *ngIf="headingAnchors.length > 0">
                <li *ngFor="let heading of headingAnchors" class="toc-heading level-{{heading.level}}">
                    <a (click)="scrollTo(heading.anchor)">{{heading.label}}</a>
                </li>
            </ul>
        </div>
        <markdown #markdownElement [src]="'./USAGE.md'" (load)="onMarkdownLoad($event)"></markdown>
    </div>
    <div class="top-button">
        <button mat-button (click)="scrollTo('toc')">Top ^</button>
    </div>
    <div class="close-button">
        <button mat-button mat-dialog-close>close</button>
    </div>
</div>
